import React from 'react';

const Content = ({
  content,
  className,
}: {
  content: JSX.Element | string | null;
  className?: string;
}): JSX.Element => {
  if (typeof content === 'string') {
    return <div className={className} dangerouslySetInnerHTML={{ __html: content }} />;
  }
  return <div className={className}>{content}</div>;
};

export default Content;
