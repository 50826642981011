import React from 'react';
import { ImageType } from './PreviewCompatibleImage';

type PageHeaderProps = {
  title?: string;
  subtitle?: string;
  image: ImageType;
};
const PageHeader = (props: PageHeaderProps): JSX.Element => {
  const { title, subtitle, image } = props;
  const imageHeight = '400px';

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
      }}
    >
      <div
        style={{
          clip: 'rect(0, auto, auto, 0)',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      >
        <div
          style={{
            position: 'fixed',
            display: 'block',
            top: 0,
            left: 0,
            width: '100vw',
            height: imageHeight,
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            backgroundImage: `url(${
              typeof image === 'string' ? image : image.childImageSharp.fluid.src
            })`,
          }}
        >
          <div
            style={{
              display: 'flex',
              height: '100%',
              lineHeight: '1',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            {title && (
              <h1
                className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
                style={{
                  boxShadow: '#b48134c0 0.5rem 0px 0px, #b48134c0 -0.5rem 0px 0px',
                  backgroundColor: '#b48134c0',
                  color: 'white',
                  lineHeight: '1',
                  padding: '0.25em',
                  margin: '1em',
                }}
              >
                {title}
              </h1>
            )}
            {subtitle && (
              <h3
                className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
                style={{
                  boxShadow: '#b48134c0 0.5rem 0px 0px, #b48134c0 -0.5rem 0px 0px',
                  backgroundColor: '#b48134c0',
                  color: 'white',
                  lineHeight: '1',
                  padding: '0.25em',
                }}
              >
                {subtitle}
              </h3>
            )}
          </div>
        </div>
      </div>
      <div
        style={{
          height: imageHeight,
        }}
      ></div>
    </div>
  );
};

export default PageHeader;
