import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import { ImageType } from '../components/PreviewCompatibleImage';
import Content from '../components/Content';
import PageHeader from '../components/PageHeader';

type IndexPageFrontmatter = {
  title: string;
  subtitle?: string;
  image: ImageType;
  content: string | JSX.Element | null;
};

export const IndexPageTemplate = (props: IndexPageFrontmatter): JSX.Element => {
  const { image, title, subtitle, content } = props;

  return (
    <div>
      <PageHeader title={title} subtitle={subtitle} image={image} />
      <section className="section section--gradient">
        <div className="container">
          <div className="section">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="content">
                  <div className="columns">
                    <div className="column is-12">
                      <Content className="content" content={content} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const IndexPage = (props: {
  data: RecursiveNonNullable<GatsbyTypes.IndexPageTemplateQuery>;
}): JSX.Element => {
  const { data } = props;
  const { frontmatter, html } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        subtitle={frontmatter.subtitle}
        content={html}
      />
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      html
      frontmatter {
        title
        subtitle
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
